header .wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    align-items: center;
}

.menu__list {
    display: flex;
    gap: 24px;
}

.menu__list--link {
    transition: .5s;

}

.menu__list--link:hover {
    color: #EAA16D;
}

.burger,
.nav__button {
    display: none;
}


.nav__button.active {
    display: flex;
}

.burger-icon {
    width: 30px;
    height: 3px;
    background-color: #333;
    margin: 6px 0;
    transition: .4s
}

@media (max-width: 1000px) {
    .logo__img {
        max-width: 170px;
    }
}

@media (max-width:480px) {
    .burger {
        display: flex;
        flex-direction: column;
        gap: 6px;
        cursor: pointer;
        position: fixed;
        z-index: 5;
        top: 20px;
        right: 20px
    }

    .menu__container.active {
        display: flex;
        flex-direction: column;
        align-items: start;
        position: fixed;
        transition: 1s;
        width: 100vw;
        height: 100vh;
        z-index: 4;
        background: rgba(20, 20, 24, 0.40);
        right: 0;
        top: 0;
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
    }

    .menu__list {
        display: flex;
        flex-direction: column;
        align-items: start;
        position: fixed;
        transition: 1s;
        width: 276px;
        height: 100vh;
        z-index: 4;
        background: #121621;
        padding: 55px 28px 0;
        right: -276px;
        top: 0;
        gap:0;
    }

    .menu__list.active {
        right: 0
    }

    .menu__list li {
        width: 100%;
        padding: 24px 0;
        border-bottom: 1px solid rgba(234, 161, 109, 0.50);;
    }

    .menu__list li:first-of-type {
        padding-top: 0;
    }

    .menu__list li:last-of-type {
        padding-bottom: 0;
        border-bottom: none;
    }

    .nav__button {
        display: flex;
        position: fixed;
        z-index: 5;
        bottom: 50px;
        font-weight: 500;
        right: -276px;
        align-items: center;
        justify-content: center;
        gap: 10px;
        padding: 20px;
        width: 100%;
        max-width: 220px;
        color: #141418;
        font-size: 16px;
        line-height: 1;
        border-radius: 50px;
        background: #FFF;
        transition: 1s;
    }

    .nav__button.active {
        right: 28px;
    }
    .nav__button:hover {
        box-shadow: 0px 4px 20px 0px rgba(255, 255, 255, 0.60);
    }
    .bar {
        width: 25px;
        height: 2px;
        background-color: #EAA16D;
        transition: .5s
    }

    .burger.active .bar:nth-child(1) {
        transform: rotate(-45deg) translate(-5.5px, 6px);
    }

    .burger.active .bar:nth-child(2) {
        opacity: 0
    }

    .burger.active .bar:nth-child(3) {
        transform: rotate(45deg) translate(-5.5px, -5.5px);
    }

    .burger.active .burger-icon {
        background-color: transparent
    }
}
@media (max-width: 480px) {
    .logo__img{
        max-width: 150px;
    }
}