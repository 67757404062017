.contact {
    max-width: 1560px;
    width: calc(100% - 40px);
    margin: 0 auto;
    padding: 98px;
    border-radius: 13px;
    border: 1px solid #92E4DD;
    margin-top: 140px;
    position: relative;
}

.contact::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 5px);
    border-radius: 100%;
    background: radial-gradient(circle at 50% 50%, #FFF 0%, rgba(146, 228, 221, 0.00) 100%);
    height: 8px;
    width: 20%;
}

.contact .wrapper {
    margin-top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.contact__title {
    width: calc(50% - 125px)
}


.contact__images {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    position: relative;
}

.contact__img:nth-of-type(1) {
    margin-top: 45px;
}

.contact_dot1,
.contact_dot2 {
    position: absolute;
    opacity: 1;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.contact_dot1 {
    top: 40%;
    left: 15%;
    animation-name: blink;
    animation-delay: 0.2s;
}

.contact_dot2 {
    top: 16%;
    left: 79%;
    animation-name: blink;
    animation-delay: 1.5s;
}


.contact__form {
    width: calc(50% - 10px);
}

.contant__inputs {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 20px;
}

.contact__input {
    width: calc(50% - 10px);
    padding: 15px 24px;
}

.contact__textarea {
    width: 100%;
    height: 170px;
    resize: none
}

.contact__input,
.contact__textarea {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.90);
    color: rgba(20, 20, 24, 0.50);
    font-size: 18px;
    line-height: 140%;
    padding: 15px 24px;
}

.contact__input:focus,
.contact__textarea:focus {
    background: #FFF;
    color: #141418;
}

.form__bottom {
    margin-top: 35px;
    display: flex;
    gap: 32px;
}

.form__checkbox {
    width: calc(100% - 250px);
    display: flex;
    gap: 13px;
    align-items: start;
}

.form__checkbox a {
    text-decoration: underline;
    transition: .5s;
}

.form__checkbox a:hover {
    text-decoration: none;
}

.contact__form-button {
    padding: 20px 44px;
    color: #141418;
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    border-radius: 50px;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    opacity: 0.5;
    transition: .5s;
    max-width: 222px;
    width: 100%;
}

.contact__form-button.active {
    opacity: 1;
}

.contact__form-button.active:hover {
    box-shadow: 0px 4px 20px 0px rgba(255, 255, 255, 0.60);
}

.form__checkbox-span {
    width: 14px;
    height: 14px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.90);
    padding: 2px;
}

.form__checkbox-span.active img {
    display: block;
}

.form__checkbox-span img {
    width: 100%;
    display: none;
}

.form__checkbox #checkbox {
    display: none;
}

.form__checkbox p {
    color: #858894;
    font-size: 14px;
    line-height: 140%;
    width: calc(100% - 27px);
}

.thanks {
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: none;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background: rgba(20, 20, 24, 0.40);
    z-index: 5;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    cursor: pointer
}

.thanks.active {
    display: flex
}

.thanks__container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 430px;
    padding: 83px;
    border-radius: 13px;
    background: linear-gradient(0deg, #FFF 0%, #FFF 100%), #141418;
    position: relative;
}

.thanks__container::after {
    content: url(../../../public/img/close-thanks.svg);
    position: absolute;
    top: 6px;
    right: 6px;
}


.thanks__container p {
    color: #141418;
    text-align: center;
    font-family: Nunito Sans;
    font-size: 32px;
}

@media (max-width:1000px) {
    .contact {
        padding: 60px 49px;
        margin-top: 110px;
    }

    .contact::after {
        height: 6px;
        width: 200px;
        transform: translate(-50%, 3px);

    }

    .contact__title {
        width: calc(50% - 85px);
    }

    .contact__images {
        flex-direction: column-reverse;
        margin-top: 80px;
    }

    .contact__img:nth-of-type(1) {
        margin-top: 30px;
        width: 240px;
    }

    .contact__img:nth-of-type(2) {
        align-self: flex-end;
        width: 170px;
    }

    .contact_dot1 {
        top: 61%;
        left: 19%;
        width: 65px;
        height: 65px;
    }

    .contact_dot2 {
        top: 12%;
        left: 73%;
        width: 37px;
        height: 37px;
    }

    .contact__input,
    .contact__textarea {
        font-size: 16px;
        padding: 10px 20px;
    }

    .contant__inputs {
        flex-direction: column;
        gap: 10px;
    }

    .contact__input {
        width: 100%;
    }

    .contact__textarea {
        height: 140px;
    }

    .form__bottom {
        margin-top: 20px;
        flex-direction: column;
        gap: 20px;
    }

    .form__checkbox {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .contact {
        padding: 30px 28px;
    }

    .contact::after {
        transform: translate(-50%, 3.5px);
    }

    .contact .wrapper {
        flex-direction: column;
    }

    .contact__images {
        display: none;
    }

    .contact__form {
        margin-top: 35px;
        width: 100%;
    }

    .contant__inputs {
        gap: 20px;
        flex-direction: row;
    }

    .contact__input {
        width: calc(50% - 10px);
    }

    .contact__input,
    .contact__textarea {
        padding: 15px 24px;
    }

    .form__bottom {
        margin-top: 30px;
        flex-direction: row;
    }

    .form__checkbox {
        width: calc(100% - 250px);
    }
}

@media (max-width: 480px) {
    .contact {
        margin-top: 85px;
        width: 100%;
        padding: 50px 19px;
    }
    .contact::after{
        width: 150px;
        height: 4px;
        transform: translate(-50%, 2.5px);
    }
    .contact__title {
        width: 100%;
    }

    .contact__form {
        margin-top: 40px;
    }

    .contant__inputs {
        gap: 18px;
    }

    .contact__input {
        width: 100%;
    }

    .contact__input,
    .contact__textarea {
        padding: 11px 21px;
    }

    .form__bottom {
        margin-top: 20px;
        flex-direction: column;
    }

    .form__checkbox {
        width: 100%;
    }

    .contact__form-button {

        width: 100%;
        max-width: 100%;
    }
}
@media (max-width: 360px) {
    .contact{
        padding: 36px 19px;
    }
    .contact__form{
        margin-top: 30px;
    }
    .contact__input, .contact__textarea{
        padding: 11px 15px;
        font-size: 14px;
    }
}