footer {
    position: relative;
    padding: 30px 0 85px;
    display: flex;
    justify-content: space-between;
    align-items: start;

}

footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="1347" height="2" viewBox="0 0 1347 2" fill="none"><path d="M0.5 1H1347" stroke="url(%23paint0_linear_22_914)" stroke-opacity="0.2"/><defs><linearGradient id="paint0_linear_22_914" x1="0.5" y1="-93.0287" x2="1347.5" y2="-93.0287" gradientUnits="userSpaceOnUse"><stop stop-color="%2392E4DD" stop-opacity="0"/><stop offset="0.521204" stop-color="%2392E4DD"/><stop offset="1" stop-color="%2392E4DD" stop-opacity="0"/></linearGradient></defs></svg>');
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.footer__links {
    width: 40%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px 0;
}

.footer__links>* {
    width: 50%;
    display: flex;
    align-items: center;
}

.footer__links a:nth-child(1) {
    color: #FFF;
    font-size: 18px;
    line-height: 140%;
}

.footer__links a:nth-child(2),
.footer__links span {
    color: #858894;
    font-size: 16px;
    line-height: 140%;
}

@media (max-width:1000px) {
    footer {
        padding: 20px 0 30px;
    }

    .footer__links {
        width: 50%;
        gap: 7px 0;
    }

    .footer__links a:nth-child(1) {
        font-size: 16px;
    }

    .footer__links a:nth-child(2),
    .footer__links span {
        font-size: 14px;

    }
}

@media (max-width: 768px) {
    footer.wrapper {
        margin-top: 90px;
        padding: 30px 0;
    }

    .footer__links {
        flex-direction: column;
        gap: 0;
    }
    .footer__links>*{
        width: 100%;
    }
    .footer__links a:nth-child(2) {
        order: 3;
    }

    .footer__links span {
        order: 2;
    }
}
@media (max-width:480px) {
    .footer__links{
        padding-top: 5px;
    }
    .footer__links span{
        margin-top: 5px;
    }
}
@media (max-width:360px) {
    footer.wrapper{
        margin-top: 75px;
        padding: 26px 0;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }
    .footer__links{
        width: 100%;
        padding: 0;
    }
    .footer__links>*{
        justify-content: center;
    }
    .footer__links a:nth-child(1){
        font-size: 14px;
    }
    .footer__links a:nth-child(2), .footer__links span{
        font-size: 12px;
    }
    .footer__links span{
        margin-top: 7px;
    }
}