@charset 'UTF-8';

/* Slider */
.slick-slider {
    margin-top: 85px;
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
    width: calc(100% + 20px);
    margin-left: -10px;
}

.slick-list:focus {
    outline: none;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}

.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}

.slick-track:after {
    clear: both;
}

.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}

.slick-slide img {
    display: block;
}

.slick-initialized .slick-slide {
    display: block;
}

/* Arrows */
.slick-prev,
.slick-next {

    font-size: 0;
    line-height: 0;
    position: absolute;
    bottom: 160px;

    display: block;

    width: auto;
    height: auto;
    padding: 0;
    cursor: pointer;
}


.slick-prev {
    left: -65px;
}

.slick-prev:before {
    content: url('../../../public/img/prev.svg');
}

.slick-next {
    right: -65px;
}

.slick-next:before {
    content: url('../../../public/img/next.svg');
}

@media (max-width: 1000px) {
    .slick-prev {
        left: 0;
        bottom: -8px;
    }

    .slick-next {
        right: 0;
        bottom: -8px;
    }
}

@media (max-width:480px) {
    .slick-list {
        width: calc(100% + 10px);
    }
}

@media (max-width:420px) {
    .slick-list {
        width: calc(100% + 20px);
    }
}

@media (max-width:360px) {
    .slick-slider {
        margin-top: 45px;
    }
}