.title {
    display: flex;
    justify-content: space-between;
    margin-top: 90px;
}

.title__block,
.title__img {
    width: calc(50% - 10px);
}

.main__title {
    color: #FFF;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 82px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    margin-top: 20px;
}

.title__block p {

    font-size: 28px;
    line-height: 140%;
    max-width: 555px;
    margin-top: 40px;
}

.title__button {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #141418;
    font-size: 18px;
    padding: 20px 44px;
    border-radius: 50px;
    background: #FFF;
    line-height: 1.1;
    transition: .5s;
    max-width: 222px;
    font-weight: 400;
}

.title__button:hover {
    box-shadow: 0px 4px 20px 0px rgba(255, 255, 255, 0.60);
}

.title__img {
    position: relative;
}

.bg__title {
    width: 100%;
}

.dot {
    position: absolute;
}

.dot1 {
    top: 27%;
    left: 24%;
}

.dot2 {
    top: 31.5%;
    left: 50%;
}

.dot3 {
    top: 27%;
    left: 64%;
}

.dot4 {
    top: 45%;
    left: 35%;
}

.dot5 {
    top: 52%;
    left: 50%;
}

.dot6 {
    top: 66%;
    left: 44%;
}

.dot {
    opacity: 1;
    animation-duration: 3s;
    /* Продолжительность анимации */
    animation-iteration-count: infinite;
    /* Бесконечное повторение */
    animation-timing-function: ease-in-out;
    /* Равномерное изменение скорости анимации */
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    /* Начальное и конечное значение */
    50% {
        opacity: 0.75;
    }

    /* Значение в середине анимации */
}

.dot1 {
    animation-name: blink;
    animation-delay: 0s;
}

.dot2 {
    animation-name: blink;
    animation-delay: 0.5s;
}

.dot3 {
    animation-name: blink;
    animation-delay: 2.3s;
}

.dot4 {
    animation-name: blink;
    animation-delay: 1s;
}

.dot5 {
    animation-name: blink;
    animation-delay: 1.7s;
}

.dot6 {
    animation-name: blink;
    animation-delay: 1.2s;
}

@media (max-width: 1000px) {
    .title {
        margin-top: 65px;
    }

    .main__title {
        margin-top: 35px;
        font-size: 56px;
    }

    .title__block p {
        font-size: 22px;
    }

    .title__button {
        margin-top: 50px;
    }

    .dot1 {
        width: 37px;
        height: 37px;
    }

    .dot2 {
        width: 45px;
        height: 45px;
    }

    .dot3 {
        width: 45px;
        height: 45px;
    }

    .dot4 {
        width: 45px;
        height: 45px;

    }

    .dot5 {
        width: 37px;
        height: 37px;

    }

    .dot6 {
        width: 45px;
        height: 45px;

    }
}
@media (max-width:768px) {
    .title{
        margin-top: 60px;
    }
    .main__title{
        font-size: 46px;
    }
    .title__img{
        width: 470px;
        position: absolute;
        right: -40px;
        z-index: -1;
    }
    .title__block p{
        margin-top: 30px;
    }
    .title__button{
        margin-top: 45px;
    }
}
@media (max-width:480px) {
    .title{
        margin-top: 30px;
        flex-direction: column;
        padding-bottom: 105px;
    }
    .main__title{
        font-size: 36px;
        width: 80%;
        margin-top: 0;
    }
    .title__block{
        width: 100%;
        position: relative;
    }
    .title__block p{
        margin-top: 20px;
        font-size: 20px;
    }
    .title__button{
        position: absolute;
        margin-top: 0;
        max-width: 100%;
        width: 100%;
        top: 405%;
    }
    .title__img{
        width: 100%;
        position: relative;
        right: 0;
        margin-top: 45px;
    }
}
@media (max-width: 480px) {
    .title{
        margin-top: 55px;
    }
    .main__title{
        width: 100%;
    }
    .title__block p{
        margin-top: 15px;
        font-size: 18px;
    }
    .title__img{
        margin-top: 25px;
    }
}

@media (max-width: 400px){
    .title__button{
        top: 370%;
    }
}

@media (max-width: 393px){
    .title__button{
        top: 325%;
    }
}
@media (max-width: 380px){
    .title__button{
        top: 300%;
    }
}
@media (max-width: 360px){
    .title__button{
        top: 300%;
    }
}