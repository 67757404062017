.faq {
    position: relative;
}

.faq::after {
    content: '';
    width: 100%;
    height: 80%;
    background-image: url('../../../public//img/faq-bg.svg');
    position: absolute;
    top: -100px;
    border-radius: 1360px;
    background-size: 100%;
    z-index: 2;
}

.faq__container {
    margin-top: 85px;
    border-radius: 13px;
    border: 1px solid #4257A0;
    position: relative;
    z-index: 3;
    background: #141418;
}

.faq__item {
    position: relative;
    border-bottom: 1px solid #4257A0;
    padding: 45px 54px;
}

.faq__item:nth-child(even)::before,
.faq__item:nth-child(odd)::after {
    content: '';
    width: 6px;
    height: 80px;
    top: 50%;
    background-image: url(../../../public/img/faq-ellipse.svg);
    position: absolute;
    z-index: 3;
}

.faq__item:nth-child(even)::before {
    transform: translate(70%, -50%);
    right: 0;
}

.faq__item:nth-child(odd)::after {
    transform: translate(-70%, -50%);
    left: 0;
}

.faq__item:last-child {
    border-bottom: 0;
}

.faq__title-top {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.faq__title {
    color: #FFF;
    font-family: Nunito Sans;
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
}

.faq__descr-block {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    width: 80%;
    display: none;
    opacity: 0;
    transition: opacity 5s ease-in-out, margin-top 5s ease-in-out;
}

.faq__descr-block.show {
    display: block;
    margin-top: 45px;
    opacity: 1;
}

.faq__descr {
    position: relative;
    top: -80px;
    transition: top 5s ease-in-out, opacity 5s ease-in-out;
    /* Анимация изменения top и opacity */
    color: #858894;
    font-size: 18px;
    line-height: 140%;
}

.faq__descr-block.show .faq__descr {
    top: 0;
    opacity: 1;
}


.faq__close {
    transition: transform 0.3s ease;
    transform: rotate(0);
    width: 30px;
    height: 30px;
    margin-top: 5px;
}

.faq__close.rotate {
    transform: rotate(45deg);
}

@media (max-width:1000px) {
    .faq::after{
       display: none;
    }
    .faq__item {
        padding: 33px 36px;
    }

    .faq__descr-block.show {
        margin-top: 25px;
        width: 100%;
    }

    .faq__descr {
        font-size: 16px;
    }

    .faq__item:nth-child(odd)::after {
        transform: translate(-50%, -50%);
        width: 5px;
    }

    .faq__item:nth-child(even)::before {
        transform: translate(60%, -50%);
        width: 5px;

    }
}

@media (max-width:768px) {
    .wrapper.faq {
        margin-top: 65px;
    }

    .faq__container {
        margin-top: 65px;
    }

    .faq__item {
        padding: 35px 25px 40px;
    }

    .faq__title {
        font-size: 24px;
    }

    .faq__descr-block.show {
        margin-top: 35px;
    }
}

@media (max-width: 480px) {
    .wrapper.faq {
        margin-top: 85px;
    }

    .faq__container {
        margin-top: 50px;
    }

    .faq__item {
        padding: 24px 16px 25px;
    }
    .faq__descr-block.show{
        margin-top: 20px;
    }
    .faq__close{
        width: 20px;
        height: 20px;
    }
}
@media (max-width: 360px) {
    .faq__container{
        margin-top: 30px;
    }
    .faq__item{
        padding: 22px 12px;
    }
    .faq__title{
        font-size: 20px;
    }
    .faq__close{
        width: 18px;
        height: 18px;
    }
    .faq__descr-block.show{
        margin-top: 13px;
    }
    .faq__descr{
        font-size: 14px;
    }
}
